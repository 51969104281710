
.search-input {
  padding-left: 43px; 
  height: 40px;
  width: 90%;
  border: 1px solid #ddd;
   margin-left: 100; /* Move the input box to the right */
  margin-right: 0; /* Ensure there is no right margin */
  border-radius: 5px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"%3E%3Cpath d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-1.42 1.42l.27.28v.79l5 4.99 1.42-1.42-4.99-5zM10 16a5.99 5.99 0 1 1 5.99-5.99A5.99 5.99 0 0 1 10 16z" fill="%23A9A9A9" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: 10px center; /* Position the icon */
  background-size: 16px 16px; /* Adjust size */
}